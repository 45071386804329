import { useContext } from 'react';
import * as makerjs from 'makerjs';
import { StoreContext } from '../store';
import { getGridScale } from '../utils/helpers';
import '../styles/grid.css';

const Grid = (props) => {

  const view = useContext(StoreContext).view;
  const gridScale = getGridScale(view);
  const p = makerjs.point.add(view.origin, [view.panOffset.x, view.panOffset.y]);

  let translateX = p[0].toFixed(2);
  let translateY = p[1].toFixed(2);

  const transform = 'translate(' + translateX + ',' + translateY + ')';

  return (
    <svg className="maker-grid" xmlns="http://www.w3.org/2000/svg">
      <defs key="cutwiseDefs1">
        <pattern x="0" y="0" width=".1" height=".1" id="makerPattern1" key="makerPattern1">
          <line x1="0" y1="0" x2="0" y2="100%" className="maker-grid_gridLine1" id="makerLine1" key="makerLine1" />
          <line x1="0" y1="0" x2="100%" y2="0" className="maker-grid_gridLine1" id="makerLine2" key="makerLine2" />
        </pattern>
        <pattern x="0" y="0" width="1" height="1" id="makerPattern2" key="makerPattern2">
          <line x1="0" y1="0" x2="0" y2="100%" className="maker-grid_gridLine2" id="makerLine3" key="makerLine3" />
          <line x1="0" y1="0" x2="100%" y2="0" className="maker-grid_gridLine2" id="makerLine4" key="makerLine4" />
        </pattern>
        <pattern x="0" y="0" width={gridScale} height={gridScale} patternUnits="userSpaceOnUse" patternTransform={transform} id="makerPattern3" key="makerPattern3">
          <rect fill="url(#makerPattern1)" width={gridScale} height={gridScale} x="0" y="0" id="makerGridPattern1" key="makerGridPattern1" />
          <rect fill="url(#makerPattern2)" width="100%" height="100%" x="0" y="0" id="makerGridPattern2" key="makerGridPattern2" />
        </pattern>
      </defs>
      <rect fill="url(#makerPattern3)" width="100%" height="100%" x="0" y="0" id="makerGrid" key="makerGrid" />
      <g transform={transform} key="makerGroup1">
        <line x1="-100%" y1="0" x2="100%" y2="0" className="maker-grid_crosshair" id="makerCrosshair1" key="makerCrosshair1" />
        <line x1="0" y1="-100%" x2="0" y2="100%" className="maker-grid_crosshair" id="makerCrosshair2" key="makerCrosshair2" />
      </g>
    </svg>
  )
}

export default Grid;