import React, { useContext } from 'react';
import { StoreContext } from '../store';

import '../styles/rulelines.css';

const RuleLines = () => {
  const state = useContext(StoreContext);
  const { x, y } = state.view.cursor;

  return (
    <svg className="maker-rule" xmlns="http://www.w3.org/2000/svg">
      <g className="maker-rule_group" key="makerRuleGroup1">
        <line className="maker-rule_lines" x1={x} y1={0} x2={x} y2={'100%'} id="makerRuleLine1" key="makerRuleLine1" strokeDasharray="2" />
        <line className="maker-rule_lines" x1={0} y1={y} x2={'100%'} y2={y} id="makerRuleLine2" key="makerRuleLine2" strokeDasharray="2" />
      </g>
    </svg>
  )
}

export default RuleLines