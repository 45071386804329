import React, { useContext, useState } from 'react';
import { Card } from 'react-bootstrap';
import { StoreContext, dispatchStore } from '../store';
import * as makerjs from 'makerjs';
import cn from 'classnames';
import {
  MdBorderClear,
  MdBorderAll,
  MdNearMe,
  MdNearMeDisabled,
  MdClosedCaptionDisabled,
  MdClosedCaption,
  MdZoomOutMap,
  MdZoomInMap,
  MdSave
} from 'react-icons/md';
import '../styles/header.css'

const Header = ( { children } ) => {

  const { options, view, content } = useContext(StoreContext);
  const dispatch = useContext(dispatchStore);

  const fitToScreen = () => {
    dispatch({ type: 'TOGGLE_FIT_SCREEN' });
  }

  const handleGridToggle = () => {
    dispatch({ type: 'TOGGLE_GRID' });
  }

  const handlePathNamesToggle = () => {
    dispatch({ type: 'TOGGLE_PATH_NAMES' });
  }

  const handlePathFlowToggle = () => {
    dispatch({ type: 'TOGGLE_PATH_FLOW' });
  }

  const handleSaveToSvg = (e) => {
    e.preventDefault();
    const svg = makerjs.exporter.toSVG(content.model);

    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'cutwise.svg';

    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  }

  return (
    <Card.Header className="maker-header d-flex justify-content-between align-items-center">
      Workbench
      <div>
        { children }
      </div>

      <div>

        <a
          className={cn('btn btn-sm btn-default me-1', { 'selected': options.showGrid })}
          href="#"
          role="button"
          title="enable/disable grid"
          onClick={handleGridToggle}
        >
          {options.showGrid ? (
            <MdBorderAll className="fs-5" />
          ) : (
            <MdBorderClear className="fs-5" />
          )}
        </a>

        <a
          className={cn('btn btn-sm btn-default me-1', { 'selected': options.showPathNames })}
          href="#"
          role="button"
          title="show/hide pathnames"
          onClick={handlePathNamesToggle}
        >
          {options.showPathNames ? (
            <MdClosedCaption className="fs-5" />
          ) : (
            <MdClosedCaptionDisabled className="fs-5" />
          )}
        </a>

        <a
          className={cn('btn btn-sm btn-default me-1', { 'selected': options.showPathFlow })}
          href="#"
          role="button"
          title="show/hide path flow"
          onClick={handlePathFlowToggle}
        >
          {options.showPathFlow ? (
            <MdNearMe className="fs-5" />
          ) : (
            <MdNearMeDisabled className="fs-5" />
          )}
        </a>

        <a
          className={cn('btn btn-sm btn-default me-1')}
          href="#"
          role="button"
          title="fit to screen"
          onClick={fitToScreen}
        >
          {view.scale === 1 ? (
            <MdZoomInMap className="fs-5" />
          ) : (
            <MdZoomOutMap className="fs-5" />
          )}
        </a>

        <a
          className={cn('btn btn-sm btn-default me-1')}
          href="#"
          role="button"
          title="save"
          onClick={(e) => {
            handleSaveToSvg(e);
          }}
        >
          <MdSave className="fs-5" />
        </a>

      </div>


    </Card.Header>
  )
}

export default Header;