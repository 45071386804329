import makerjs from 'makerjs';

function Polygon(number_of_sides, radius, offset_angle, radius_on_flats) {
  this.models = {
    example: new makerjs.models.Polygon(
      number_of_sides,
      radius,
      offset_angle,
      radius_on_flats
    )
  };
}

Polygon.metaParameters = [
  { title: "number of sides", type: "range", min: 3, max: 24, value: 6 },
  { title: "radius", type: "range", min: 1, max: 100, value: 50 },
  { title: "offset angle", type: "range", min: 0, max: 180, value: 0 },
  {
    title: "radius on flats (vs radius on vertexes)",
    type: "bool",
    value: false
  }
];

export default Polygon;