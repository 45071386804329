import { useContext, useState } from 'react';
import { StoreContext, dispatchStore } from '../store';

import '../styles/drawing.css';

const Drawing = ({
 xmlns,
 style,
 mainViewRef,
}) => {

  const { options, view, content, drawing } = useContext(StoreContext);
  const dispatch = useContext(dispatchStore);

  let boundingRect = mainViewRef.current.getBoundingClientRect();

  const [textValue, setTextValue] = useState('');

  let calcWidth = Math.abs(drawing.end.x - drawing.start.x) * view.scale;
  let calcHeight = Math.abs(drawing.end.y - drawing.start.y) * view.scale;

  let calcX = Math.min(drawing.start.x, drawing.end.x) * view.scale;
  let calcY = Math.min(drawing.start.y, drawing.end.y) * view.scale;

  return (
    <div className="maker-drawing_view">
      <svg
        xmlns={xmlns}
        width={boundingRect.width}
        height={boundingRect.height}
        style={style}
        className="maker-drawing_svg"
        viewBox={`0 0 ${boundingRect.width} ${boundingRect.height}`}
      >
        <g className="maker-svg_group">
          {view.cursorTool === 'square' && (
            <rect
              x={calcX}
              y={calcY}
              width={calcWidth}
              height={calcHeight}
              fill="none"
              stroke="black"
              strokeWidth="1"
            />
          )}
          {view.cursorTool === 'rectangle' && (
            <rect
              x={calcX}
              y={calcY}
              width={calcWidth}
              height={calcHeight}
              fill="none"
              stroke="black"
              strokeWidth="1"
            />
          )}
          {view.cursorTool === 'circle' && (
            <circle
              cx={calcX}
              cy={calcY}
              r={Math.sqrt(Math.pow(drawing.end.x - drawing.start.x, 2) + Math.pow(drawing.end.y - drawing.start.y, 2))}
              fill="none"
              stroke="black"
              strokeWidth="1"
            />
          )}
          {view.cursorTool === 'line' && (
            <line
              x1={drawing.start.x}
              y1={drawing.start.y}
              x2={drawing.end.x}
              y2={drawing.end.y}
              fill="none"
              stroke="black"
              strokeWidth="1"
            />
          )}
          {view.cursorTool === 'text' && (
            <rect
              x={calcX}
              y={calcY}
              width={calcWidth}
              height={calcHeight}
              fill="none"
              stroke="black"
              strokeWidth="1"
              strokeDasharray="5,5"
            >
              <text
                x={calcX}
                y={calcY}
                width={calcWidth}
                height={calcHeight}
                fill="black"
                fontSize="12"
                fontFamily="Arial"
                textLength={textValue.length}
              />
            </rect>
          )}
          {view.cursorTool === 'freehand' && (
            drawing.paths.map((d, i) => {

              let nextX1 = drawing.paths[i + 1] ? drawing.paths[i + 1].x1 : drawing.end.x;
              let nextY1 = drawing.paths[i + 1] ? drawing.paths[i + 1].y1 : drawing.end.y;

              if (drawing.rightClick) {
                nextX1 = drawing.start.x;
                nextY1 = drawing.start.y;
              }

              return (
                <line
                  key={i}
                  x1={d.x1}
                  y1={d.y1}
                  x2={nextX1}
                  y2={nextY1}
                  fill="none"
                  stroke="black"
                  strokeWidth="1"
                />
              )
            })
          )}

        </g>
      </svg>
    </div>
  )
}

export default Drawing