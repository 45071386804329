import { useState, useRef, useEffect, useContext } from 'react';
import { StoreContext, dispatchStore } from '../store';
import { getCursorCoordinates } from '../utils/helpers';
import '../styles/context-menu.css';


const ContextMenu = ({ item }) => {
  const [contextMenuState,setContextMenuState] = useState({ visible: false, x: 0, y: 0 });
  const { options, view } = useContext(StoreContext);
  const contextMenuRef = useRef(null);
  const handleMenuItemClick = (e) => {
    console.log('handleMenuItemClick', e);
  }

  const coords = getCursorCoordinates(view);

  const divWidth = contextMenuRef.current ? contextMenuRef.current.offsetWidth : 0;
  const divHeight = contextMenuRef.current ? contextMenuRef.current.offsetHeight : 0;

  const localY = coords.y - contextMenuRef.current.offsetWidth / 2;
  const localX = coords.x - contextMenuRef.current.offsetHeight / 2;

  useEffect(() => {
    if (contextMenuState.visible) {
      contextMenuRef.current.focus();
    }
  }, [contextMenuState]);

  return (
    <div
      ref={contextMenuRef}
      className="context-menu"
      style={{ top: `${localY}px`, left: `${localX}px` }}
    >
      <div className="context-menu-item" onClick={() => handleMenuItemClick("Option 1")}>
        Option 1
      </div>
      <div className="context-menu-item" onClick={() => handleMenuItemClick("Option 2")}>
        Option 2
      </div>
    </div>
  )
}

export default ContextMenu;