import './App.css';
import Maker from './maker';
import * as makerjs from 'makerjs';
import Polygon from './maker/utils/polygon';
import {randomString} from "./maker/utils/helpers";

const square = makerjs.model.moveRelative(
    makerjs.model.rotate(
        makerjs.model.center(
            new makerjs.models.Square(10)
        ),
        45),
    [0, 15]);

const dome = new makerjs.models.Dome(30, 30);

let model1 = {
    models: {
        square: square,
        dome: dome
    }
}

let model3 = {
    models: {
        rect1: makerjs.model.move(new makerjs.models.Rectangle(50, 100), [0, 0]),
        ring1: makerjs.model.move(new Polygon(3, 50, 0, 0), [0, 0]),
        bc1: makerjs.model.move(new makerjs.models.BoltCircle(90, 4, 10), [0, 0]),
        bc2: makerjs.model.move(new makerjs.models.BoltCircle(55, 7, 6, 30), [0, 0])
    },
    paths: {
        line1: new makerjs.paths.Line([0, 0], [50, 50]),
    }
};
let newChains = []
makerjs.model.findChains(model3, function (chains, loose, layer) {
    newChains = chains;
}, {
    pointMatchingDistance: 0.1,
    contain: true,
});

let newModel = {};
let index = 0;
for (let chain of newChains) {
    newModel['path' + index] = makerjs.chain.toNewModel(chain);
    index++;
}

const model = {models:newModel};

function App() {
    return (
        <>
            <div className="outer-container">
                <Maker model={model}/>
            </div>
        </>
    );
}

export default App;
