import React, { useContext } from 'react';
import { getCursorCoordinates } from '../utils/helpers';
import { StoreContext } from '../store';
import '../styles/statusbar.css';

const Statusbar = () => {

  const { options, view } = useContext(StoreContext);

  let viewWidth = view.viewSize.width / view.scale;
  let detail = 0;

  while (viewWidth && viewWidth < Math.pow(10, -(detail - 2))) {
    detail++;
  }

  let coords = getCursorCoordinates(view);

  let x = coords.x;
  let ySign = options.yDirection === 'naturalUp' ? -1 : 1;
  let y = coords.y * ySign;

  return (
    <div className="maker-statusbar">
      <span>X: {x.toFixed(2)}, Y: {y.toFixed(2)} | zoom: {(view.scale * 100).toFixed()}%</span>
    </div>
  )
}

export default Statusbar