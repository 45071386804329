import {createContext, useEffect, useReducer} from 'react';
import {
  convert,
  getCursorCoordinates,
  isMakerModel,
  naturalFit,
  randomString,
  renderOptions,
  screenFit
} from '../utils/helpers';
import * as makerjs from 'makerjs';
import opentype from 'opentype.js';

let MakerFonts = [];

opentype.load('/fonts/Roboto-Regular.ttf', function (err, font) {
  if (err) {
    alert('Could not load font: ' + err);
  } else {
    MakerFonts['Roboto-Regular'] = font;
  }
});


const wheelZoomDelta = 0.1;

const initialContent = {
  measurement: null,
  model: null,
  svgNode: null
};

const initialOptions = {
  fitOnScreen: false,
  showGrid: true,
  showPathNames: false,
  showPathFlow: false,
  yDirection: 'naturalUp',
  unitString: undefined
};

const initialDrawing = {
  isDrawing: false,
  rightClick: false,
  start: { x: 0, y: 0 },
  end: { x: 0, y: 0 },
  text : '|',
  fontSize: 10,
  font: 'Roboto-Regular',
  paths: [],
  event: null
}

const initialView = {
  cursor: { x: 0, y: 0 },
  cursorIcon: 'default',
  cursorTool: null,
  isMouseDown: false,
  showRuleLines: true,
  origin: [0, 0],
  panOffset: { x: 0, y: 0 },
  scale: 1,
  viewOffset: { x: 0, y: 0 },
  viewSize: { width: 0, height: 0 }
};


const initialState = {
  content: initialContent,
  options: initialOptions,
  drawing: initialDrawing,
  view: initialView
};

const handleToolAction = (state) => {

  const { drawing, view, content, options } = state;

  if (drawing.start.x === drawing.end.x && drawing.start.y === drawing.end.y && view.cursorTool !== 'freehand') {
    return content.model;
  }

  const ySign = options.yDirection === 'naturalUp' ? -1 : 1;
  const pointX = drawing.start.x < drawing.end.x ? drawing.start.x : drawing.end.x;
  let pointY = (drawing.end.y > drawing.start.y ? drawing.end.y : drawing.start.y) * ySign;

  let newPath = {
    paths: {}
  }

  switch (view.cursorTool) {

    case 'line': {

      let line = makerjs.path.move(
        new makerjs.paths.Line([drawing.start.x , drawing.start.y * ySign], [drawing.end.x, drawing.end.y * ySign]), [drawing.start.x, drawing.start.y * ySign]
      )

      return {
        ...content.model,
        models: {
          ...content.model?.models,
          [randomString()]: {
            paths: {
              ...newPath.paths,
              [randomString()]: line
            }
          }
        }
      }
    }
    case 'circle': {
      const radius = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.end.x, drawing.end.y]);
      let circlePointY = drawing.start.y < drawing.end.y ? drawing.start.y : drawing.end.y;
      circlePointY = circlePointY * ySign;

      let circle = makerjs.path.move(
        new makerjs.paths.Circle([drawing.start.x, drawing.start.y], radius), [pointX, circlePointY]
      )

      return {
        ...content.model,
        models: {
          ...content.model?.models,
          [randomString()]: {
            paths: {
              ...newPath.paths,
              [randomString()]: circle
            }
          }
        }
      }
    }
    case 'square': {

      const width = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.end.x, drawing.start.y]);
      const height = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.start.x, drawing.end.y]);
      const squareSize = Math.max(width, height);

      return {
        ...content.model,
        models: {
          ...content.model?.models,
          [randomString()]: makerjs.model.move(
            new makerjs.models.Square(squareSize), [pointX, pointY]
          ),
        }
      }
    }
    case 'rectangle': {
      const width = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.end.x, drawing.start.y]);
      const height = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.start.x, drawing.end.y]);

      return {
        ...content.model,
        models: {
          ...content.model?.models,
          [randomString()]: makerjs.model.move(
            new makerjs.models.Rectangle(width, height), [pointX, pointY]
          ),
        }
      }
    }
    case 'text': {

      let textPointY = drawing.start.y < drawing.end.y ? drawing.start.y : drawing.end.y;
      textPointY = (textPointY * ySign) - drawing.fontSize;

      return {
        ...content.model,
        models: {
          ...content.model?.models,
          [randomString()]: makerjs.model.move(
            new makerjs.models.Text(MakerFonts[drawing.font], drawing.text, drawing.fontSize, true, true), [pointX, textPointY]
          )
        }
      }
    }

    case 'freehand': {

      drawing.paths.map((d, i) => {

        let nextY1 = drawing.paths[i + 1] ? drawing.paths[i + 1].y1 : drawing.paths[0].y1;
        let nextX1 = drawing.paths[i + 1] ? drawing.paths[i + 1].x1 : drawing.paths[0].x1;

        newPath.paths[randomString()] = makerjs.path.move(
          new makerjs.paths.Line([d.x1, d.y1 * ySign], [nextX1, nextY1 * ySign]), [d.x1, d.y1 * ySign]
        )

      });

      return {
        ...content.model,
        models: {
          ...content.model?.models,
          [randomString()]: {
            ...newPath
          }
        }
      }

    }

  }
}

export const reducer = (state, action) => {

  switch (action.type) {

    case 'SET_MODEL': {
      const { model } = action;

      let svgString = null;

      if (model && isMakerModel(model)) {
        let options = renderOptions(state.view, makerjs.measure.modelExtents(model));

        svgString = makerjs.exporter.toSVG(model, options);
      }

      if (model && typeof model === 'string') {
        svgString = model;
      }

      let svgNode = svgString ? convert(svgString) : null;
      let measurement = model && isMakerModel(model) ? makerjs.measure.modelExtents(model) : null;
      let newContent = {
        measurement: measurement,
        model: model && isMakerModel(model) ? model : null,
        svgNode: svgNode
      }

      let fittingState = {
        ...state,
        content: newContent,
      }

      let needsRefit = state.content.model === null;

      return {
        ...state,
        view: state.options.fitOnScreen ? screenFit(fittingState) : (needsRefit ? naturalFit(fittingState) : state.view),
        content: newContent,
      }

    }

    case 'SET_TEXT_FIELD_PARAMS': {
      return {
        ...state,
        drawing: {
          ...state.drawing,
          text: action.text,
          fontSize: action.fontSize,
        }
      }
    }

    case 'TOGGLE_FIT_SCREEN': {
      let newFit = !state.options.fitOnScreen;
      return {
        ...state,
        options: {
          ...state.options,
          fitOnScreen: newFit
        },
        view: newFit ? screenFit(state) : naturalFit(state)
      }
    }

    case 'SET_VIEW_MEASUREMENTS': {
      let newView = {
        ...state.view,
        viewOffset: action.point,
        viewSize: action.size
      }
      return {
        ...state,
        view: newView
      }
    }

    case 'MOUSE_WHEEL': {
      let sign = action.delta > 0 ? -1 : 1;
      let newScale = state.view.scale * (1 + sign * wheelZoomDelta);
      let zoomRatio = newScale / state.view.scale;
      let cursorCoords = getCursorCoordinates(state.view);
      let previousScaledCenter = makerjs.point.scale([cursorCoords.x, cursorCoords.y], state.view.scale);
      let newScaledCenter = makerjs.point.scale([cursorCoords.x, cursorCoords.y], zoomRatio);
      let centerPointDiff = makerjs.point.subtract(previousScaledCenter, newScaledCenter);

      let newOptions = {
        ...state.options,
        fitOnScreen: state.options.fitOnScreen && newScale !== 1 ? false : state.options.fitOnScreen
      }

      let offset = makerjs.point.add([state.view.panOffset.x, state.view.panOffset.y], centerPointDiff);
      let newView = {
        ...state.view,
        scale: newScale,
        panOffset: { x: offset[0], y: offset[1] }
      }

      return {
        ...state,
        options: newOptions,
        view: newView
      }
    }

    case 'MOUSE_DOWN': {

      let newCursor = makerjs.point.subtract([ action.point.x, action.point.y ], [ state.view.viewOffset.x, state.view.viewOffset.y ]);

      if (state.view.cursorTool) {

        let coords = getCursorCoordinates(state.view);

        let newPath = {
          type: 'line',
          x1: coords.x,
          y1: coords.y,
          x2: coords.x,
          y2: coords.y,
        }

        return {
          ...state,
          drawing: {
            ...state.drawing,
            isDrawing: true,
            start: { x: coords.x, y: coords.y },
            end: { x: coords.x, y: coords.y },
            event: action.event,
            paths: [
              ...state.drawing.paths,
              newPath
            ]
          },
          view: {
            ...state.view,
            cursor: { x: newCursor[0], y: newCursor[1] },
          }
        }
      }

      return {
        ...state,
        view: {
          ...state.view,
          cursor: { x: newCursor[0], y: newCursor[1] },
          isMouseDown: true,
        }
      }
    }

    case 'MOUSE_UP': {
      let newCursor = makerjs.point.subtract([ action.point.x, action.point.y ], [ state.view.viewOffset.x, state.view.viewOffset.y ]);

      if (state.view.cursorTool) {

        let newModel = handleToolAction(state);
        let coords = getCursorCoordinates(state.view);

        let stillDrawing = (state.view.cursorTool === 'freehand' && !state.drawing.rightClick);

        if (!stillDrawing) {
          return {
            ...state,
            content: {
              ...state.content,
              model: newModel
            },
            drawing: {
              ...state.drawing,
              isDrawing: false,
              rightClick: false,
              event: action.event,
              paths: [],
            },
            view: {
              ...state.view,
              cursor: {x: newCursor[0], y: newCursor[1]},
            }
          }
        }
      }

      return {
        ...state,
        view: {
          ...state.view,
          cursor: { x: newCursor[0], y: newCursor[1] },
          isMouseDown: false,
        }
      }
    }

    case 'MOUSE_MOVE': {
      let newCursor = makerjs.point.subtract([ action.point.x, action.point.y ], [ state.view.viewOffset.x, state.view.viewOffset.y ]);
      let panDelta = [0,0];

      if (state.view.isMouseDown) {
        panDelta = makerjs.point.subtract([newCursor[0], newCursor[1]], [state.view.cursor.x, state.view.cursor.y]);
      }

      let coords = getCursorCoordinates(state.view);

      if (state.drawing.isDrawing) {
        return {
          ...state,
          drawing: {
            ...state.drawing,
            end: {x: coords.x, y: coords.y}
          },
          view: {
            ...state.view,
            cursor: {x: newCursor[0], y: newCursor[1]},
          }
        }
      }

      let panOffset = makerjs.point.add([state.view.panOffset.x, state.view.panOffset.y], panDelta);

      return {
        ...state,
        view: {
          ...state.view,
          cursor: { x: newCursor[0], y: newCursor[1] },
          panOffset: { x: panOffset[0], y: panOffset[1] },
        }
      }
    }

    case 'END_FREEHAND_TOOL' : {
      return {
        ...state,
        drawing: {
          ...state.drawing,
          rightClick: true,
        }
      }
    }

    case 'TOGGLE_GRID': {
      return {
        ...state,
        options: {
          ...state.options,
          showGrid: !state.options.showGrid
        }
      }
    }

    case 'TOGGLE_PATH_NAMES': {
      return {
        ...state,
        options: {
          ...state.options,
          showPathNames: !state.options.showPathNames
        }
      }
    }

    case 'TOGGLE_PATH_FLOW': {
      return {
        ...state,
        options: {
          ...state.options,
          showPathFlow: !state.options.showPathFlow
        }
      }
    }

    case 'SET_SELECTED_TOOL': {
      return {
        ...state,
        view: {
          ...state.view,
          cursorIcon: action.icon,
          cursorTool: action.tool
        }
      }
    }

    default: {
      return state;
    }
  }

}

export const StoreContext = createContext(initialState);
export const dispatchStore = createContext({});

const StoreProvider = ({ options, model, children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    dispatch({ type: 'SET_MODEL', model: model ?? null });
  }, [model]);

  return (
    <StoreContext.Provider value={state}>
      <dispatchStore.Provider value={dispatch}>
        {children}
      </dispatchStore.Provider>
    </StoreContext.Provider>
  )
}

export default StoreProvider;