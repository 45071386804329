import React, { useEffect, useState, useRef } from 'react';
import Blueprint from './components/blueprint';
import StoreProvider from './store';
import './styles/index.css';

const Maker = ( { model, options, children } ) => {
  return (
    <StoreProvider
      model={model}
      options={options}
    >
      <Blueprint
        children={children}
      />
    </StoreProvider>
  )
}

export default Maker