import React, { useEffect, useContext, useState, useRef, Children, cloneElement } from 'react';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { StoreContext, dispatchStore } from '../store';
import {
  elementOnClick,
  elementOnDblClick,
  elementOnMouseEnter,
  elementOnMouseLeave,
} from '../utils/handlers';
import Header from './header';
import Pointers from './pointers';
import Grid from './grid';
import Statusbar from './statusbar';
import Toolbar from './toolbar';
import RuleLines from './rulelines';
import ContextMenu from './context-menu';
import Drawing from './drawing';

import '../styles/blueprint.css';

const Blueprint = ({ children }) => {

  const [contextMenuItem, setContextMenuItem] = useState(null);
  const { options, view, content, drawing } = useContext(StoreContext);
  const dispatch = useContext(dispatchStore);
  let childElements = null;
  let svgClasses = '';

  let svgStyle = {
    marginLeft: view.panOffset.x,
    marginTop: view.panOffset.y,
  }

  useEffect(() => {
    console.log('content.model', content.model);

    dispatch({ type: 'SET_MODEL', model: content.model });
  }, [content.model]);

  let { width, height } = view.scale;

  if ( content.svgNode && content.svgNode.props ) {
    width = parseFloat(content.svgNode.props.width) * view.scale;
    height = parseFloat(content.svgNode.props.height) * view.scale;

    if ( content.svgNode.props.children ) {
      childElements = Children.map(content.svgNode.props.children, (child, index) => {

        if ( child.type === 'g' ) {
          return cloneElement(child, {
            className: 'maker-svg_group',
            key: index,
            onClick: elementOnClick,
            onDoubleClick: elementOnDblClick,
            onMouseEnter: elementOnMouseEnter,
            onMouseLeave: elementOnMouseLeave,
            // onContextMenu: (e) => {
            //   e.preventDefault();
            //   setContextMenuItem(e);
            // }
          });
        }

      })
    }
  }

  const mainViewRef = useRef(null);
  const updatePageOffset = () => {
    if (mainViewRef && mainViewRef.current) {
      let boundingRect = mainViewRef.current.getBoundingClientRect();
      let element = mainViewRef.current;
      let curLeft = 0;
      let curTop = 0;

      if (element.offsetParent) {
        do {
          curLeft += element.offsetLeft;
          curTop += element.offsetTop;
        } while (element = element.offsetParent);
      }
      dispatch({
        type: 'SET_VIEW_MEASUREMENTS',
        point: { x: curLeft, y: curTop },
        size: { width: boundingRect.width, height: boundingRect.height },
      });
    }
  }

  if (options.showPathNames) {
    svgClasses += ' show-pathnames';
  }

  if (options.showPathFlow) {
    svgClasses += ' show-pathflow';
  }

  useEffect(() => {
    if (mainViewRef.current) {
      updatePageOffset();

      mainViewRef.current.addEventListener('wheel', (e) => {
        e.preventDefault();
        dispatch({ type: 'MOUSE_WHEEL', delta: e.deltaY });
      })

    }
  }, [mainViewRef]);

  useEffect(() => {
    const handleResize = () => {
      updatePageOffset();
    }
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    }
  }, []);

  const handleMouse = (e, type) => {
    dispatch({type, point: {x: e.pageX, y: e.pageY}, event: e});
  }

  const handleContextMenu = (e) => {
    e.preventDefault();
    e.stopPropagation();
    if (view.cursorTool === 'freehand') {
      dispatch({ type: 'END_FREEHAND_TOOL', rightClick: true });
    }
  }

  return (
    <Container className="maker-container">
      <Row>

        <Col>
          <Card>
            <Toolbar />
          </Card>
        </Col>

        <Col xs={10}>
          <Card>
            <Header children={children} />
            <Card.Body style={{padding:0}}>
              <section
                className="maker-main"
              >
                {options.showGrid && (
                  <Grid />
                )}
                <div
                  className="maker-view_params"
                  style={{cursor: view.cursorIcon}}
                  onContextMenu={handleContextMenu}
                >
                  <div
                    ref={mainViewRef}
                    className="maker-main_view noselect"
                    touch-action="none"
                    onMouseDown={(e) => {
                      handleMouse(e, 'MOUSE_DOWN')
                    }}
                    onMouseMove={(e) => {
                      handleMouse(e, 'MOUSE_MOVE')
                    }}
                    onMouseUp={(e) => {
                      handleMouse(e, 'MOUSE_UP')
                    }}

                    onDragStart={(e) => {
                      handleMouse(e, 'MOUSE_DRAG_START')
                    }}

                    onDragEnd={(e) => {
                      handleMouse(e, 'MOUSE_DRAG_END')
                    }}
                  >
                    <div
                      id="maker-svg-container"
                      className={'maker-view_svg_container' + svgClasses}
                    >
                      {drawing.isDrawing && (
                        <Drawing
                          xmlns={content?.svgNode?.props.xmlns || 'http://www.w3.org/2000/svg'}
                          width={width}
                          height={height}
                          style={svgStyle}
                          mainViewRef={mainViewRef}
                          viewBox={content?.svgNode?.props.viewBox || `0 0 ${width} ${height}`}
                        />
                      )}
                      {content.svgNode && content.svgNode.props && (
                        <svg
                          className="maker-view_svg"
                          xmlns={content.svgNode.props.xmlns || 'http://www.w3.org/2000/svg'}
                          width={width}
                          height={height}
                          style={svgStyle}
                          viewBox={content.svgNode.props.viewBox || `0 0 ${width} ${height}`}
                          id={content.svgNode.props.id || 'maker-svg'}
                          children={childElements}
                        />
                      )}
                    </div>
                    {view.isMouseDown && !view.cursorTool && (
                      <Pointers />
                    )}
                    {view.showRuleLines && !view.isMouseDown && (
                      <RuleLines />
                    )}
                    {contextMenuItem && <ContextMenu item={contextMenuItem} />}
                  </div>
                </div>
                <Statusbar />
              </section>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );

}

export default Blueprint