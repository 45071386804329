import ContextMenu from '../components/context-menu';

const elementClasses = {
  hoverActive: 'maker-pointer_over',
  dblClickActive: 'maker-dbl_click'
}

export const elementOnClick = (e) => {
  console.log(e.target);
}

export const elementOnDblClick = (e) => {
  switch (e.target.tagName) {
    case 'rect':
    case 'circle' : {
      if (e.target.classList.contains(elementClasses.dblClickActive)) {
        e.target.classList.remove(elementClasses.dblClickActive);
        break;
      }

      e.target.classList.add(elementClasses.dblClickActive);
      break;
    }
    case 'g': {
      e.target.childNodes.forEach((child) => {
        if (child.classList.contains(elementClasses.dblClickActive)) {
          child.classList.remove(elementClasses.dblClickActive);
          return;
        }
        child.classList.add(elementClasses.dblClickActive);
      });
      break;
    }
    case 'text': {
      break;
    }
    default: {
      let parent = e.target.parentNode;
      while (parent) {
        if (parent.tagName === 'g') {
          parent.childNodes.forEach((child) => {
            if (child.classList.contains(elementClasses.dblClickActive)) {
              child.classList.remove(elementClasses.dblClickActive);
              return;
            }
            child.classList.add(elementClasses.dblClickActive);
          });
        }
        parent = parent.parentNode;
      }
    }
  }
}

export const elementOnMouseEnter = (e) => {
  switch (e.target.tagName) {
    case 'rect':
    case 'circle' : {
      e.target.classList.add(elementClasses.hoverActive);
      break;
    }
    case 'g': {
      e.target.childNodes.forEach((child) => {
        child.classList.add(elementClasses.hoverActive);
      });
      break;
    }
    case 'text': {
      break;
    }
    default: {
      let parent = e.target.parentNode;
      while (parent) {
        if (parent.tagName === 'g') {
          parent.childNodes.forEach((child) => {
            child.classList.add(elementClasses.hoverActive);
          });
        }
        parent = parent.parentNode;
      }
    }
  }
}

export const elementOnMouseLeave = (e) => {
  switch (e.target.tagName) {
    case 'rect':
    case 'circle' : {
      e.target.classList.remove(elementClasses.hoverActive);
      break;
    }
    case 'g': {
      e.target.childNodes.forEach((child) => {
        child.classList.remove(elementClasses.hoverActive);
      });
      break;
    }
    case 'text': {
      break;
    }
    default: {
      let parent = e.target.parentNode;
      while (parent) {
        if (parent.tagName === 'g') {
          parent.childNodes.forEach((child) => {
            child.classList.remove(elementClasses.hoverActive);
          });
        }
        parent = parent.parentNode;
      }
    }
  }
}

export const elementOnContextMenu = (e) => {
  e.preventDefault();
  e.stopPropagation();
  return (
    <ContextMenu event={e} />
  );
}