import {
  useContext,
  useEffect,
  useRef,
  useState
} from 'react';
import {
  OverlayTrigger,
  Tooltip,
  Card,
  Container,
  Row,
  Col,
  Form
} from 'react-bootstrap';
import {
  svgToCursor
} from '../utils/helpers';
import {
  StoreContext,
  dispatchStore
} from '../store';
import {
  MdLayersClear,
  MdOutlinePolyline,
  MdCropSquare,
  MdOutlineMotionPhotosOn,
  MdShowChart,
  MdTextFields,
  MdOutlineRectangle,
} from 'react-icons/md';
import {
  BiPointer,
  BiSolidPointer,
  BiSolidFileImport
} from 'react-icons/bi';
import {
  BsFiletypeSvg
} from 'react-icons/bs';
import {
  DxfIcon,
  SvgIcon
} from "../icons";
import {
  LuMousePointerClick
} from 'react-icons/lu';
import cn from 'classnames';
import * as makerjs from 'makerjs';

const pixelsPerMM = 3.7795275591;

const Toolbar = () => {

  const { options, view, content, drawing } = useContext(StoreContext);
  const dispatch = useContext(dispatchStore);

  const [fontSize,setFontSize] = useState(10);
  const [textValue,setTextValue] = useState('Lorem Ipsum');

  const [tool, setTool] = useState({ type: null, icon: null});


  const svgFileInput = useRef(null);

  useEffect(() => {
    document.addEventListener('keyup', (e) => {
      if (e.key === 'Escape') {
        handleToolSelect(e, null, null)
      }
    });
    document.addEventListener('contextmenu', (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (tool.type === 'freehand') {
        dispatch({ type: 'END_FREEHAND_TOOL', rightClick: true });
      }

    });
  }, []);

  const drawingWidth = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.end.x, drawing.start.y]);
  const drawingHeight = makerjs.measure.pointDistance([drawing.start.x, drawing.start.y], [drawing.start.x, drawing.end.y]);

  const squareSize = Math.max(drawingWidth, drawingHeight);
  
  useEffect(() => {
    dispatch({ type: 'SET_TEXT_FIELD_PARAMS', fontSize: fontSize, text: textValue });
  }, [textValue, fontSize]);

  useEffect(() => {
    let cursor = 'default';
    if ( tool.type ) {
      cursor = svgToCursor(tool.icon);
    }
    dispatch({ type: 'SET_SELECTED_TOOL', icon: cursor, tool: tool.type });
  }, [tool]);

  const onClearModel = () => {
    dispatch({ type: 'SET_MODEL', model: null });
  }

  const handleToolSelect = (e, selectedTool, component) => {
    e.preventDefault();
    if (selectedTool === tool.type) {
      selectedTool = null;
      component = null;
    }
    setTool({ type: selectedTool, icon: component });
  }

  const onTextChange = (e) => {
    setTextValue(e.target.value);
  }

  const renderTooltip = (toolName) => (
    <Tooltip id="button-tooltip">
      { toolName }
    </Tooltip>
  );

  const handleSvgImport = (e) => {
    console.log(e)
  }

  return (
    <>
      <Card.Header className="maker-header_toolbar d-flex justify-content-between align-items-center">
        Toolbar
        <a
          className={cn('btn btn-sm btn-danger')}
          href="#"
          role="button"
          title="enable/disable grid"
          onClick={onClearModel}
        >
          <MdLayersClear className="fs-5" />
        </a>
      </Card.Header>
      <Card.Body className="maker-body_toolbar">
        <Container>
          <Row>

            <Col xs={12} className="d-flex justify-content-center">
              <OverlayTrigger placement="top" overlay={renderTooltip('Cursor')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === null })}
                  href="#"
                  role="button"
                  title="normal"
                  onClick={(e) => {
                    handleToolSelect(e, null, null)
                  }}
                >
                  <BiSolidPointer className="fs-5" />
                </a>
              </OverlayTrigger>

              {/*<a*/}
              {/*  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'dragging' })}*/}
              {/*  href="#"*/}
              {/*  role="button"*/}
              {/*  title="dragging"*/}
              {/*  onClick={(e) => {*/}
              {/*    handleToolSelect(e, 'dragging', <BiPointer />)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <BiPointer className="fs-5" />*/}
              {/*</a>*/}

              {/*<a*/}
              {/*  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'clicking' })}*/}
              {/*  href="#"*/}
              {/*  role="button"*/}
              {/*  title="clicking"*/}
              {/*  onClick={(e) => {*/}
              {/*    handleToolSelect(e, 'clicking', <LuMousePointerClick />)*/}
              {/*  }}*/}
              {/*>*/}
              {/*  <LuMousePointerClick className="fs-5" />*/}
              {/*</a>*/}

            </Col>

            <Col xs={12} className="d-flex justify-content-center mt-2">

              <OverlayTrigger placement="top" overlay={renderTooltip('Line Tool')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'line' })}
                  href="#"
                  role="button"
                  title="line"
                  onClick={(e) => {
                    handleToolSelect(e, 'line', <MdShowChart />)
                  }}
                >
                  <MdShowChart className="fs-5" />
                </a>
              </OverlayTrigger>

              <OverlayTrigger placement="top" overlay={renderTooltip('Square Tool')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'square' })}
                  href="#"
                  role="button"
                  title="square"
                  onClick={(e) => {
                    handleToolSelect(e, 'square', <MdCropSquare />)
                  }}
                >
                  <MdCropSquare className="fs-5" />
                </a>
              </OverlayTrigger>

              <OverlayTrigger placement="top" overlay={renderTooltip('Rectangle Tool')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'rectangle' })}
                  href="#"
                  role="button"
                  title="other"
                  onClick={(e) => {
                    handleToolSelect(e, 'rectangle', <MdOutlineRectangle />)
                  }}
                >
                  <MdOutlineRectangle className="fs-5" />
                </a>
              </OverlayTrigger>

            </Col>

            <Col xs={12} className="d-flex justify-content-center mt-2">
              <OverlayTrigger placement="top" overlay={renderTooltip('Circle Tool')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'circle' })}
                  href="#"
                  role="button"
                  title="circle"
                  onClick={(e) => {
                    handleToolSelect(e, 'circle', <MdOutlineMotionPhotosOn />)
                  }}
                >
                  <MdOutlineMotionPhotosOn className="fs-5" />
                </a>
              </OverlayTrigger>

              <OverlayTrigger placement="top" overlay={renderTooltip('Connected lines Tool')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'freehand' })}
                  href="#"
                  role="button"
                  title="freehand"
                  onClick={(e) => {
                    handleToolSelect(e, 'freehand', <MdOutlinePolyline />)
                  }}
                >
                  <MdOutlinePolyline className="fs-5" />
                </a>
              </OverlayTrigger>

              <OverlayTrigger placement="top" overlay={renderTooltip('Text Tool')}>
                <a
                  className={cn('btn btn-sm btn-default me-1', { 'selected': tool.type === 'text' })}
                  href="#"
                  role="button"
                  title="text"
                  onClick={(e) => {
                    handleToolSelect(e, 'text', <MdTextFields />)
                  }}
                >
                  <MdTextFields className="fs-5" />
                </a>
              </OverlayTrigger>

            </Col>

            <Col xs={12} className="d-flex mt-2">
              <hr style={{ color: '#ff150f', backgroundColor: '#ff150f', height: 1, width: '100%' }} />
            </Col>

            <Col xs={12} className="d-flex justify-content-center mt-2">
              <a
                className={cn('btn btn-sm btn-default me-1')}
                href="#"
                role="button"
                title="text"
                onClick={(e) => {
                  svgFileInput.current.click();
                }}
              >
                <input
                  type="file"
                  id="import-svg"
                  ref={svgFileInput}
                  style={{ display: 'none' }}
                  onChange={handleSvgImport}
                />
                <DxfIcon className="fs-5" />
              </a>

              <a
                className={cn('btn btn-sm btn-default me-1')}
                href="#"
                role="button"
                title="text"
                onClick={(e) => {
                  svgFileInput.current.click();
                }}
              >
                <input
                  type="file"
                  id="import-svg"
                  ref={svgFileInput}
                  style={{ display: 'none' }}
                  onChange={handleSvgImport}
                />
                <SvgIcon className="fs-5" />
              </a>

            </Col>

          </Row>

          {tool.type === 'text' && (
            <Row className="text-tool-extra">

              <Col xs={12} className="d-flex mt-2">
                <hr style={{ color: '#ff150f', backgroundColor: '#ff150f', height: 1, width: '100%' }} />
              </Col>

              <Col xs={12} className="d-flex mt-2">
                <Form className="w-100">

                  <Form.Group>
                    <Form.Label>Text</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter text"
                      value={textValue}
                      onChange={onTextChange}
                    />
                  </Form.Group>

                  <Form.Group>
                    <Form.Label>Fontsize</Form.Label>
                    <Form.Select
                      size="sm"
                      value={fontSize}
                      onChange={(e) => setFontSize(e.target.value)}
                    >
                      <option value={10}>10</option>
                      <option value={12}>12</option>
                      <option value={14}>14</option>
                      <option value={16}>16</option>
                      <option value={18}>18</option>
                    </Form.Select>
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}

          {tool.type === 'square' && (
            <Row className="rectangle-tool-extra">
              <Col xs={12} className="d-flex mt-2">
                <hr style={{ color: '#ff150f', backgroundColor: '#ff150f', height: 1, width: '100%' }} />
              </Col>

              <Col xs={12} className="d-flex mt-2">
                <Form className="w-100">
                  <Form.Group>
                    <Form.Label>Width (mm)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter width"
                      value={(squareSize * pixelsPerMM).toFixed()}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Height (mm)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter height"
                      value={(squareSize * pixelsPerMM).toFixed()}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}

          {tool.type === 'rectangle' && (
            <Row className="rectangle-tool-extra">
              <Col xs={12} className="d-flex mt-2">
                <hr style={{ color: '#ff150f', backgroundColor: '#ff150f', height: 1, width: '100%' }} />
              </Col>

              <Col xs={12} className="d-flex mt-2">
                <Form className="w-100">
                  <Form.Group>
                    <Form.Label>Width (mm)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter width"
                      value={(drawingWidth * pixelsPerMM).toFixed()}
                    />
                  </Form.Group>
                  <Form.Group>
                    <Form.Label>Height (mm)</Form.Label>
                    <Form.Control
                      size="sm"
                      type="text"
                      placeholder="Enter height"
                      value={(drawingHeight * pixelsPerMM).toFixed()}
                    />
                  </Form.Group>
                </Form>
              </Col>
            </Row>
          )}

        </Container>
      </Card.Body>
    </>
  )
}

export default Toolbar