import React, { useContext } from 'react';
import { StoreContext } from '../store';
import '../styles/pointers.css';

const Pointers = () => {

  const state = useContext(StoreContext);
  const { x, y } = state.view.cursor;

  return (
    <svg className="maker-pointers" xmlns="http://www.w3.org/2000/svg">
      <g className="maker-pointers_group" key="makerPointersGroup1">
        <line className="maker-pointer_lines" x1={x} y1={0} x2={x} y2={'100%'} id="makerPointerLine1" key="makerPointerLine1" />
        <line className="maker-pointer_lines" x1={0} y1={y} x2={'100%'} y2={y} id="makerPointerLine2" key="makerPointerLine2" />
      </g>
    </svg>
  )

}

export default Pointers;