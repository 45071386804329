import React, { useContext, useState, useRef, createElement } from 'react';
import ReactDOMServer from 'react-dom/server';
import { StoreContext, reducer, dispatchStore } from '../store';
import { possibleStandardNames, noTextChildNodes } from '../constants';
import makerjs from 'makerjs';

const pixelsPerInch = 96;

const __read = (o, n) => {
  let m = typeof Symbol === "function" && o[Symbol.iterator];
  if (!m) return o;
  let i = m.call(o), r, ar = [], e;
  try {
    while ((n === undefined || n-- > 0) && !(r = i.next()).done) ar.push(r.value);
  }
  catch (error) {
    e = { error };
  }
  finally {
    try {
      if (r && !r.done && (m = i["return"])) m.call(i);
    }
    finally {
      if (e) throw e.error;
    }
  }
  return ar;
}

const styleToObject = (style) => {
  let attributes = style.split(/ ?; ?/);
  return attributes.reduce((result, attribute) => {
    let attr = __read(attribute.split(/ ?: ?/), 2), key = attr[0], value = attr[1];
    if (key && value) {
      result[key.replace(/-(\w)/g, (m, l) => l.toUpperCase())] = Number.isNaN(Number(value)) ? value : Number(value);
    }
    return result;
  }, {});
}

export const getGridScale = (view) => {
  let gridScale = 1;
  const { scale } = view;
  while (scale * gridScale < 6) {
    gridScale *= 10;
  }

  while (scale * gridScale > 60) {
    gridScale /= 10;
  }

  return gridScale * 10 * scale;
}

export const randomString = (length = 6) => {
  let chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += chars.charAt(Math.floor(Math.random() * chars.length));
  }
  return result;
}

const parseName = (name) => {
  if (/[a-z]+[A-Z]+[a-z]+/.test(name)) {
    return name;
  }
  return name.toLowerCase();
}

const parseAttributes = (node, reactKey) => {
  let attributes = {
    key: reactKey,
  };

  if (node instanceof Element) {

    let nodeClassNames = node.getAttribute('class');
    if (nodeClassNames) {
      attributes.className = nodeClassNames;
    }

    __read(node.attributes).forEach((attr) => {
      switch (attr.name) {
        case 'class': {
          break;
        }
        case 'style': {
          attributes[attr.name] = styleToObject(attr.value);
          break;
        }
        case 'allowfullscreen':
        case 'allowpaymentrequest':
        case 'async':
        case 'autofocus':
        case 'autoplay':
        case 'checked':
        case 'controls':
        case 'default':
        case 'defer':
        case 'disabled':
        case 'formnovalidate':
        case 'hidden':
        case 'ismap':
        case 'itemscope':
        case 'loop':
        case 'multiple':
        case 'muted':
        case 'nomodule':
        case 'novalidate':
        case 'open':
        case 'readonly':
        case 'required':
        case 'reversed':
        case 'selected':
        case 'typemustmatch': {
          attributes[possibleStandardNames[attr.name] || attr.name] = true;
          break;
        }
        default: {
          attributes[possibleStandardNames[attr.name] || attr.name] = attr.value;
        }
      }
    });

  }

  return attributes;

}

const parseChildren = (childNodeList, level, options) => {
  let children = [];
  let index = 0;
  let childNodes = Array.from(childNodeList);
  childNodes.forEach((childNode) => {
    let child = convertFromNode(childNode, { ...options, index, level: level + 1 });
    if (Array.isArray(child)) {
      children = children.concat(child);
    } else if (child !== null) {
      children.push(child);
    }
    index++;
  });
  return children;
}

const convertFromString = (input, options) => {
  const { nodeOnly = false, type = 'text/html', selector = 'body > *' } = options;
  try {
    const parser = new DOMParser();
    const doc = parser.parseFromString(input, type);
    const node = doc.querySelector(selector);

    if (!(node instanceof Node)) {
      throw new TypeError('Error parsing input');
    }
    if (nodeOnly) {
      return node;
    }
    return convertFromNode(node, options);
  } catch (e) {
    console.error(e);
  }
  return null;
}

const convertFromNode = (input, options) => {
  const { actions = [], index = 0, level = 0, randomKey } = options;
  let node = input;
  let key = ''.concat(level, '-').concat(index);
  let result = [];

  if (randomKey && level === 0) {
    key = ''.concat(randomString(), '-').concat(key)
  }

  if (Array.isArray(actions)) {
    actions.forEach((action) => {
      if (action.condition(node, key, level)) {
        if (typeof action.pre === 'function') {
          node = action.pre(node, key, level);
          if (!(node instanceof Node)) {
            throw new TypeError('Error parsing input');
          }
        }
        if (typeof action.post === 'function') {
          result.push(action.post(node, key, level));
        }
      }
    });
  }

  if (result.length) {
    return result;
  }

  switch (node.nodeType) {
    case 1: {
      return createElement(parseName(node.nodeName), parseAttributes(node, key), parseChildren(node.childNodes, level, options));
    }
    case 3: {
      let nodeText = (node.nodeValue) ? node.nodeValue.toString() : '';

      if (/^\s+$/.test(nodeText) && !/[\u00A0\u202F]/.test(nodeText)) {
        return null;
      }

      if (!node.parentNode) {
        return nodeText;
      }

      let parentNodeName = node.parentNode.nodeName.toLowerCase();
      if (noTextChildNodes.includes(parentNodeName)) {
        return null;
      }

      return nodeText;

    }
    case 8: {
      return null;
    }
    default: {
      return null;
    }
  }

}

export const convert = (input, options = {}) => {
  if (typeof input === 'string') {
    return convertFromString(input, options);
  }
  if (input instanceof Node) {
    return convertFromNode(input, options);
  }
  return null;
}

export const isMakerModel = (model) => {
  return model.paths !== undefined || model.models !== undefined;
}

const getNaturalSize = (measure) => {
  return {
    width: measure.width,
    height: measure.height
  };
}

export const getCursorCoordinates = (view) => {
  let position = makerjs.point.subtract([view.cursor.x, view.cursor.y], [view.panOffset.x, view.panOffset.y]);
  let positionScaled = makerjs.point.scale(position, 1 / view.scale)
  return {
    x: positionScaled[0],
    y: positionScaled[1]
  }
}

export const naturalFit = (state) => {
  const { view, content } = state;

  if (!content.measurement) {
    let offset = makerjs.point.scale([view.viewSize.width, view.viewSize.height], 0.5);
    view.panOffset = { x: offset[0], y: offset[1] };
    return view;
  }

  view.scale = 1;
  view.panOffset = { x: 0, y: 0 };

  if (content.model && content.model.units) {
    view.scale = makerjs.units.conversionScale(content.model.units, makerjs.unitType.Inch);
    view.scale *= pixelsPerInch;
  }

  let offset = makerjs.point.scale([view.viewSize.width, view.viewSize.height], 0.5);
  view.panOffset = { x: offset[0], y: offset[1] };
  return view;
}

export const screenFit = (state) => {
  const { view, content } = state;

  if (!content.measurement) {

    let offset = makerjs.point.scale([view.viewSize.width, view.viewSize.height], 0.5);

    view.panOffset = { x: offset[0], y: offset[1] };
    return view;
  }

  let naturalSize = getNaturalSize(content.measurement);
  let scaleHeight = view.viewSize.height / naturalSize.height;
  let scaleWidth = view.viewSize.width / naturalSize.width;

  view.scale = Math.min(scaleHeight, scaleWidth);
  let middle = makerjs.point.scale([view.viewSize.width, view.viewSize.height], 0.5);
  let offset = makerjs.point.add(middle, makerjs.point.scale([
    -(naturalSize.width / 2 + content.measurement.low[0]),
    (naturalSize.height / 2 + content.measurement.low[1])
  ], view.scale));

  view.panOffset = { x: offset[0], y: offset[1] };

  return view;

}

export const renderOptions = (view, measurement) => {
  let fontSize = 2;
  let flowSize = 2;
  if (measurement) {
    let size = getNaturalSize(measurement);
    let minSize = Math.min(size.width, size.height);
    fontSize = 1 / 25 * minSize;
    flowSize = 1 /100 * minSize;
  }
  return {
    origin: view.origin,
    annotate: true,
    flow: { size: flowSize },
    svgAttrs: {
      'id': 'drawing'
    },
    fontSize: fontSize + 'px',
    useSvgPathOnly: false
  }
}

export const svgToCursor = (component) => {
  const svgString = ReactDOMServer.renderToString(component);
  const base64 = btoa(svgString);
  return `url('data:image/svg+xml;base64,${base64}') 0 0, auto`;
}